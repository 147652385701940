import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

export const DetailHeading = (props) => {
  return <SHeading>{props.heading}</SHeading>;
};

const SHeading = styled.h4`
  font-weight: 700;
  line-height: 1;
  color: ${Color.dark};
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${Color.border};
  margin-bottom: 0.5rem;
`;
