import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

export const SectionHeading = (props) => {
  return <SHeading>{props.heading}</SHeading>;
};

const SHeading = styled.h2`
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  color: ${Color.primary};
  &::before {
    content: "";
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: ${Color.primary};
    border-radius: 100vh;
    margin-right: 1rem;
  }
`;
