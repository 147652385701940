import React from "react";
import styled from "@emotion/styled";

export const ImgChangeBtn = (props) => {
  return <SImg src={props.img} alt="product" />;
};

const SImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
