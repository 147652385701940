import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

export const ProductBtn = (props) => {
  return (
    <SButton data-color={props.color}>
      <span className="material-icons-outlined">{props.icon}</span>
      <span>{props.txt}</span>
    </SButton>
  );
};

const SButton = styled.button`
  margin-top: 0.5rem;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 8px;
  /* hover */
  @media screen and (min-width: 1025px) {
    &:hover {
      opacity: 0.7;
    }
  }
  span {
    line-height: 1;
    color: ${Color.primary};
    &:first-of-type {
      margin-right: 1rem;
      font-size: 1.5rem;
      font-weight: 300;
    }
    &:last-of-type {
      font-size: 0.9rem;
      font-weight: 700;
    }
  }
  &:first-of-type {
    margin-top: 0;
  }
  &[data-color="primary"] {
    border: 1px solid ${Color.primary};
    background-color: ${Color.primary};
    span {
      color: ${Color.light};
    }
  }
  &[data-color="light"] {
    border: 1px solid ${Color.primary};
    background-color: ${Color.light};
    span {
      color: ${Color.primary};
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    &:active {
      opacity: 0.7;
    }
  }
`;
