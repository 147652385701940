import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";
import { Link } from "gatsby";

export const CategoryCard = (props) => {
  return (
    <SGridItem>
      <Link to={props.href}>
        <span>{props.enLabel}</span>
        <h4>{props.jpLabel}</h4>
      </Link>
    </SGridItem>
  );
};

const SGridItem = styled.div`
  border: 1px solid ${Color.border};
  border-radius: 8px;
  text-align: center;
  transition: all 0.2s;
  z-index: 1;
  a {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    span {
      font-size: 0.9rem;
      font-family: "Titillium Web", sans-serif;
      line-height: 1;
      transition: all 0.2s;
    }
    h4 {
      margin-top: 4px;
      font-size: 1rem;
      line-height: 1;
      font-weight: 500;
      transition: all 0.2s;
    }
  }

  /* hover */
  @media screen and (min-width: 1025px) {
    &:hover {
      border: 1px solid ${Color.primary};
      a {
        span {
          color: ${Color.primary};
        }
        h4 {
          color: ${Color.primary};
        }
      }
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    &:active {
      border: 1px solid ${Color.primary};
      a {
        span {
          color: ${Color.primary};
        }
        h4 {
          color: ${Color.primary};
        }
      }
    }
  }
`;
