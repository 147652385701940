import React from "react";
import { useState } from "react";
import styled from "@emotion/styled";

import ImgProduct001 from "../../images/product_001.jpg";
import ImgProduct002 from "../../images/product_002.jpg";
import ImgProduct003 from "../../images/product_003.jpg";
import { ImgChangeBtn } from "../button/imgChangeBtn";

export const ProductImgArea = () => {
  const [isShow, setIsShow] = useState(1);
  const onClickHandler001 = () => setIsShow(1);
  const onClickHandler002 = () => setIsShow(2);
  const onClickHandler003 = () => setIsShow(3);
  return (
    <SArea>
      <SBtn>
        <SButton onClick={onClickHandler001}>
          <ImgChangeBtn img={ImgProduct001} />
        </SButton>
        <SButton onClick={onClickHandler002}>
          <ImgChangeBtn img={ImgProduct002} />
        </SButton>
        <SButton onClick={onClickHandler003}>
          <ImgChangeBtn img={ImgProduct003} />
        </SButton>
      </SBtn>
      <SImg>
        <img data-img={isShow} src={ImgProduct001} alt="product" />
        <img data-img={isShow} src={ImgProduct002} alt="product" />
        <img data-img={isShow} src={ImgProduct003} alt="product" />
      </SImg>
    </SArea>
  );
};

const SArea = styled.div`
  width: 48%;
  display: flex;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const SBtn = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const SButton = styled.button`
  width: 64px;
  height: 64px;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  /* hover */
  @media screen and (min-width: 1025px) {
    &:hover {
      opacity: 0.7;
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    &:active {
      opacity: 0.7;
    }
  }
`;

const SImg = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    &[data-img="1"] {
      &:nth-of-type(1) {
        opacity: 1;
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        opacity: 0;
      }
    }
    &[data-img="2"] {
      &:nth-of-type(1) {
        opacity: 0;
      }
      &:nth-of-type(2) {
        opacity: 1;
      }
      &:nth-of-type(3) {
        opacity: 0;
      }
    }
    &[data-img="3"] {
      &:nth-of-type(1) {
        opacity: 0;
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        opacity: 1;
      }
    }
  }
`;
