import React from "react";

import { DefaultLayout } from "../components/layout/defaultLayout";
import { ProductWrapper } from "../components/wrapper/productWrapper";
import { CategoryWrapper } from "../components/wrapper/categoryWrapper";

const ProductPage = () => {
  return (
    <DefaultLayout pageTitle="製品A" headerTitle="PRODUCT">
      <ProductWrapper />
      <CategoryWrapper />
    </DefaultLayout>
  );
};

export default ProductPage;
