import React from "react";
import styled from "@emotion/styled";

import { ProductBtn } from "../button/productBtn";
import { DetailHeading } from "../heading/detailHeading";

export const ProductLeadArea = () => {
  return (
    <SArea>
      <STitle>
        <p>カテゴリ名</p>
        <h3>商品A</h3>
      </STitle>
      <SButton>
        <ProductBtn
          txt="資料のダウンロード"
          icon="file_download"
          color="light"
        />
        <ProductBtn txt="カートに入れる" icon="shopping_cart" color="primary" />
      </SButton>
      <SDetail>
        <DetailHeading heading="この商品について" />
        <p>
          サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
        </p>
      </SDetail>
      <SDetail>
        <DetailHeading heading="商品の特徴" />
        <p>
          サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
        </p>
      </SDetail>
      <SDetail>
        <DetailHeading heading="商品仕様" />
        <ul>
          <li>仕様：サンプルテキスト</li>
          <li>仕様：サンプルテキスト</li>
          <li>仕様：サンプルテキスト</li>
          <li>仕様：サンプルテキスト</li>
          <li>仕様：サンプルテキスト</li>
          <li>仕様：サンプルテキスト</li>
        </ul>
      </SDetail>
    </SArea>
  );
};

const SArea = styled.div`
  width: 48%;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

const STitle = styled.div`
  p {
    line-height: 1;
    font-weight: 500;
    font-size: 0.8rem;
  }
  h3 {
    line-height: 1;
    margin-top: 0.5rem;
  }
`;

const SButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const SDetail = styled.div`
  margin-top: 2rem;
  p,
  ul {
    font-size: 0.9rem;
    list-style: none;
  }
`;
