import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

import { ProductImgArea } from "../area/productImgArea";
import { ProductLeadArea } from "../area/productLeadArea";

export const ProductWrapper = () => {
  return (
    <SWrapper className="productWrapper">
      <ProductImgArea />
      <ProductLeadArea />
    </SWrapper>
  );
};

const SWrapper = styled.section`
  border-top: 1px solid ${Color.border};
  padding: 2rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;
