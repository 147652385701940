import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../styles/color";

import { SectionHeading } from "../heading/sectionHeading";
import { CategoryCard } from "../card/categoryCard";
import { CategoryList } from "../../utils/categoryList";

export const CategoryWrapper = () => {
  return (
    <SWrapper className="caegoryWrapper">
      <SectionHeading heading="CATEGORY" />
      <SGrid>
        {CategoryList.map(({ href, enLabel, jpLabel, key }) => {
          return (
            <CategoryCard
              href={href}
              enLabel={enLabel}
              jpLabel={jpLabel}
              key={key}
            />
          );
        })}
      </SGrid>
    </SWrapper>
  );
};

const SWrapper = styled.section`
  border-top: 1px solid ${Color.border};
  padding: 2rem 0;
`;

const SGrid = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(320px, 100%), 1fr));
  gap: 8px;
`;
